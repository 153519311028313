<template>
  <div>
    <div class="content">

      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">碳排统计</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="newView">
              <!-- <el-button type="primary" @click="openViewDialog" icon="el-icon-plus" class="custom-button">新建公告</el-button> -->

              <div class="day" v-for="(item, index) in dayList" :key="index" :class="day == index + 1 ? 'day1' : ''"
                @click="dayFn(index)">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              <el-form :inline="true" :model="formInline" class="demo-form-inline form">
                <el-form-item label="日期：">
                  <el-date-picker v-show="timeType=='date'" :append-to-body="false" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input"
                    v-model="formInline.updateTime" style="width: 100%;"></el-date-picker>
                  <el-date-picker v-show="timeType=='month'" :append-to-body="false" type="month" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input"
                    v-model="formInline.updateTime" style="width: 100%;"></el-date-picker>
                  <el-date-picker v-show="timeType=='year'" :append-to-body="false" type="year" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input"
                    v-model="formInline.updateTime" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="newView1">
              <el-button type="primary" @click="openViewDialog(0)" class="custom-button custom-button-yellow">
                <div class="primaryIcon">
                  <img src="../../assets/images/TBIcon.png" alt="">
                  <div>图表</div>
                </div>
              </el-button>
              <el-button type="primary" @click="openViewDialog(1)" class="custom-button">
                <div class="primaryIcon">
                  <img src="../../assets/images/SJIcon.png" alt="">
                  <div>数据</div>
                </div>
              </el-button>

            </div>

          </div>


        </div>
        <div class="searchContent" v-show="showType == 0">
          <div class="titleEcharts"> 碳排统计</div>
        </div>
        <div class="searchContent" v-show="showType == 0">
          <div id="myEcharts" class="mycharts">
          </div>
        </div>
        <div class="" v-if="showType == 1">
          <el-table  class="table" :data="tableData" style="width: 100%">
            <el-table-column prop="ts" align="center" label="日期" width="300">
            </el-table-column>
            <el-table-column align="center" prop="dianNeng" label="电能">
            </el-table-column>
            <el-table-column align="center" prop="tanPait" label="碳排">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
import * as echarts from "echarts";
export default {
  name: 'Administration',
  data() {
    return {
      formInline: {
        updateTime: '',//创建时间
      },

      showType: 0,
      treeTitle: [],
      treeText: '',
      selectContent: '',
      inputValue: '',
      defaultProps: {
        children: 'children',
        id: 'id',
        label: 'label'
      },
      dayList: ['按日', '按月', '按季', '按年'],
      day: 1,
      stationNumber: '',
      staTime: '',
      endTime: '',
      tableData: [],
      timeType:'date'

    };
  },
  // components:{demo},
  created(){
    this.$store.commit('increment', '碳排分析')
    this.$store.commit('selectChild', '碳排统计')
    this.$store.commit("selectChildren", "");

  },

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    // this.getList()
    this.drawEnergy()
    this.getDay()
  },
  computed: {

  },


  methods: {
    dayFn(index) {
      this.day = index + 1
      this.formInline.updateTime=''
      if(index==0){
        this.timeType='date'
      }
      if(index==1 || index==2){
        this.timeType='month'
      }else
      if(index==3){
        this.timeType='year'
      }
      this.drawEnergy()
    },
    openViewDialog(index) {
      this.showType = index
    },

    //查询列表
    onSubmit() {
      this.drawEnergy()
    },
    drawEnergy() {
      this.stationNumber = sessionStorage.getItem('stationNumber')
      let type;
      if (this.day == 1) {
        this.getDay()
        type=1
      // } else if (this.day == 2) {
      //   this.getZhou(this.formInline.updateTime)
      } else if (this.day == 2) {
        this.getYue(this.formInline.updateTime)
        type=3
      } else if (this.day == 3) {
        this.getJI(this.formInline.updateTime)
        type = 4
      } else if (this.day == 4) {
        this.getYear(this.formInline.updateTime)
        type = 5
      }
      let obj = {
        stationNumber: this.stationNumber,
        type: type,
        staTime: this.staTime,
        endTime: this.endTime,
      }
      if (this.formInline.updateTime && this.day == 1) {
        obj.staTime = this.formInline.updateTime
        obj.endTime = this.formInline.updateTime
      }
      emissionsCarbonApi.statisticsApi(obj).then(res => {
        this.tableData = res.data
        let dataX = []
        let dataSer = []
        res.data.forEach(item => {
          dataSer.push(item.tanPait)
          if (this.day == 1) {

            dataX.push(item.ts.slice(11, 16))
          } else {
            dataX.push(item.ts.slice(0, 10))
          }
          // let time =item.ts.slice(11,16)
          // console.log(time);
        })
        var myEcharts = echarts.init(document.getElementById("myEcharts"));
        // 判断当前元素上是否已经有图表实例
        if (echarts.getInstanceByDom(myEcharts)) {
          // 如果有，先销毁
          echarts.dispose(myEcharts);
        }

        var option = {
          title: {
            text: "单位:tCO2e",
            left: 10,
            textStyle: {
              color: "#aed6ff",
              fontSize:16
            }
          },

          legend: {
            data: ["碳排统计"],
            right: '31px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          toolbox: {
            show: false,
            feature: {
              dataZoom: {
                yAxisIndex: false
              },
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            bottom: 90,
            left: '4%',
            right: '3%'
          },
          dataZoom: [
            {
              type: 'inside'
            },
            {
              type: 'slider'
            }
          ],
          xAxis: {
            data: dataX,
            silent: false,
            splitLine: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              // interval: 30, 
              textStyle: {
                color: '#aed6ff' // 设置 x 轴标签字体颜色
              }
            }
          },
          yAxis: {
            splitArea: {
              show: false
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',//
                color: "#0b2159"
              },
            },
            axisLabel: {
              color: '#aed6ff' // 
            }
          },
          legend: {
            data: ["碳排统计"],
            right: '31px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          backgroundColor: '#071635',
          series: [
            {
              name: "碳排统计",
              barMaxWidth: 10, // 最大宽度
              type: 'bar',
              data: dataSer,
              // Set `large` for large data amount
              large: true,
              itemStyle: {
                normal: {
                  color: '#E0AB34' // 设置柱子颜色为蓝色
                }
              }

            }
          ]
        };


        myEcharts.setOption(option);
      })

    },
    //获取周
    getZhou() {
      let now = new Date();
      let dayOfWeek = now.getDay();
      let numDay = now.getDate();
      let start = new Date(now);
      start.setDate(numDay - dayOfWeek + (dayOfWeek == 0 ? -6 : 1));
      let end = new Date(now);
      end.setDate(numDay + (7 - dayOfWeek));
      this.staTime = start.toISOString().slice(0, 10)
      this.endTime = end.toISOString().slice(0, 10)
      console.log("这周的周一日期是：" + this.staTime);
      console.log("这周的周日日期是：" + this.endTime);
    },
    getYue(dateString) {
      let now;
      if (dateString) {
        now = new Date(dateString);
      } else {
        now = new Date();
      }
      // let now = new Date();
      let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      this.staTime = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate()
      this.endTime = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate()
      console.log("这个月的第一天是：" + this.staTime);
      console.log("这个月的最后一天是：" + this.endTime);
    },
    getJI(dateString) {
      let now;
      if (dateString) {
        now = new Date(dateString);
      } else {
        now = new Date();
      }
      let startMonth = Math.floor(now.getMonth() / 3) * 3;
      let startDay = new Date(now.getFullYear(), startMonth, 1);
      let endMonth = Math.floor(now.getMonth() / 3) * 3 + 2;
      let endDay = new Date(now.getFullYear(), endMonth + 1, 0);
      this.staTime = startDay.getFullYear() + '-' + (startDay.getMonth() + 1).toString().padStart(2, '0') + '-' + startDay.getDate().toString().padStart(2, '0')
      this.endTime = endDay.getFullYear() + '-' + (endDay.getMonth() + 1).toString().padStart(2, '0') + '-' + endDay.getDate().toString().padStart(2, '0')
      console.log("季度的第一天是： " + this.staTime);
      console.log("季度的最后一天是： " + this.endTime);
    },
    getYear(dateString) {
      let now;
      if (dateString) {
        now = new Date(dateString);
      } else {
        now = new Date();
      }
      let firstDayOfYear = new Date(now.getFullYear(), 0, 1);
      let lastDayOfYear = new Date(now.getFullYear(), 12, 0);
      this.staTime = firstDayOfYear.getFullYear() + '-' + (firstDayOfYear.getMonth() + 1).toString().padStart(2, '0') + '-' + firstDayOfYear.getDate().toString().padStart(2, '0')
      this.endTime = lastDayOfYear.getFullYear() + '-' + (lastDayOfYear.getMonth() + 1).toString().padStart(2, '0') + '-' + lastDayOfYear.getDate().toString().padStart(2, '0')
      console.log("今年的第一天是： " + this.staTime);
      console.log("今年的最后一天是： " + this.endTime);
    },
    getDay() {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.staTime = yesterday
      this.endTime = yesterday
      console.log(this.endTime);
    },


  },
};
</script>
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/rightBG.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 400px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
  height: 35px;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #AED6FF;
}

.custom-button {
  background: #176ad2;
}

.searchContent {
  margin: 11px 25px 16px 26px;
}

.search {
  display: flex;
  justify-content: space-between;
  padding: 14px 26px 13px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}



::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.newView {
  display: flex;

}

.day {
  background-color: #041B44;
  /* background-color: #002C6C; */
  cursor: pointer;
  border-radius: 3px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover {
  color: #ffc74a;
  background-color: #002C6C;
}

.day1 {
  color: #ffc74a;
  background-color: #002C6C;

}

.mycharts {
  width: 100%;
  height: 800px;
}

.titleEcharts {
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
}

::v-deep.el-tree {
  width: 300px;
}

.custom-button-yellow {
  background: #E0AB34;
  border-color: #E0AB34;
}

.primaryIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table :deep() .el-table__body tr:hover td:first-child {
  border-left-color: #176ad2 !important;
  border-left-width: 2px !important;
}

::v-deep .el-table__body-wrapper {
  height: 67vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
/* 修改日期选择器样式 */
::v-deep .el-icon-date {
  color: #aed6ff;
}

::v-deep .el-picker-panel {
  background: #041c4c;
  color: #aed6ff;
  border-color: #041c4c;
}

::v-deep .el-date-picker__header-label {
  color: #aed6ff;
}

::v-deep .el-picker-panel__icon-btn {
  color: #aed6ff;
}

::v-deep .el-date-table th {
  color: #aed6ff;
  border-color: #aed6ff;
}

::v-deep .el-date-table .disabled div {
  background: #041c4c;
  color: #aed6ff;
}

::v-deep .table{
  padding: 0;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
  background: #071734 !important;
}
</style>